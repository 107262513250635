// Import Vue
import Vue from "vue";

// Import Framework7
import Framework7 from "framework7/framework7-lite.esm.bundle.js";

// Import Framework7 Keypad Plugin
import "framework7-plugin-keypad/dist/framework7-keypad.min";
import "framework7-plugin-keypad/dist/framework7-keypad.min.css";

// Import Framework7-Vue Plugin
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";

// Import Framework7 Styles
import "framework7/css/framework7.bundle.css";

// Import Icons and App Custom Styles
import "../css/icons.css";
import "../css/app.css";

// Import App Component
import App from "../components/app.vue";

// Init Framework7-Vue Plugin
Framework7.use(Framework7Keypad);
Framework7.use(Framework7Vue);

// Global Vue function
Vue.mixin({
    methods: {
        // Show message
        showMessage: function(message, type) {
            if (type === "error") {
                this.$f7.toast
                    .create({
                        text: message,
                        position: "top",
                        closeTimeout: 2000,
                    })
                    .open();
            }
            if (type === "success") {
                this.$f7.toast
                    .create({
                        text: message,
                        position: "top",
                        closeTimeout: 2000,
                    })
                    .open();
            }
        },
        postSettings: function() {
            let body =
                '{"action":"postData", "username": "' +
                this.$root.$data.credentials.username +
                '", "password":"' +
                this.$root.$data.credentials.password +
                '", "field":"userdata", "data": ' +
                JSON.stringify(this.$root.$data.settings) +
                "}";

            debugger;

            fetch(this.$root.$data.endpoint + "/admin/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "A5C2AD00B7",
                },
                cache: "default",
                body: body,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === "ok") {
                        console.log("settings saved");
                    } else {
                        console.log("error: settings not saved");
                    }
                });
        },
    },
});

// Init App
new Vue({
    el: "#app",
    render: (h) => h(App),

    data: {
        credentials: { username: "", password: "", lastLogin: "" },
        order: {
            cart: [],
            deliveryDate: "",
            customerId: "",
            customerEmail: "",
            orderText: "",
        },
        appInfo: { version: "1.0.39", date: "19.11.2024", updateVersion: "" },
        lastApiGet: "",
        products: [],
        customers: [],
        barcodes: [],
        settings: { manualScan: false, externalScan: false, liveScan: true },
        endpoint: "https://hvr.endloop.ch",
        //endpoint: "https://hvr-test.endloop.ch",
        //endpoint: "http://localhost:8282",
        //env: "dev",
        env: "prod",
    },

    // Register App Component
    components: {},
});
